import Vue from 'vue';
import VueResource from 'vue-resource';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(VueResource);
Vue.use(VueCookies);

Vue.router = router;
Vue.http.options.root = '';

Vue.prototype.globalEvents = new Vue();

router.afterEach(() => {
  // Vue.prototype.globalEvents = new Vue();
  Vue.nextTick(() => {
    window.scrollTo(0, 0);
  });
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
