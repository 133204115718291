<template>
  <v-app>
    <v-app-bar flat fixed :class="[{'scrolled' : navigation.scrolled}, {'darkened': !isLandingPage()}]">
      <v-toolbar-title>
        AM NUSSBAUM
      </v-toolbar-title>
      <v-spacer/>
      <v-btn text @click="closeMenu('#location')" v-if="$vuetify.breakpoint.lgAndUp">
        Die Lage
      </v-btn>
      <v-btn text @click="closeMenu('#building')" v-if="$vuetify.breakpoint.lgAndUp">
        Das Gebäude
      </v-btn>
      <v-btn text @click="closeMenu('#apartments')" v-if="$vuetify.breakpoint.lgAndUp">
        Die Wohnungen
      </v-btn>
      <v-btn text @click="closeMenu('#contact')" v-if="$vuetify.breakpoint.lgAndUp">
        Kontakt
      </v-btn>
      <v-toolbar-items>
        <div :class="['navigation-hamburger-menu', {'open':navigation.menu}]"
             @click="openNavigationMenu()" v-if="$vuetify.breakpoint.mdAndDown">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </v-toolbar-items>
    </v-app-bar>

    <v-dialog v-model="navigation.menu" fullscreen hide-overlay origin="top right"
              content-class="dialog-navigation-content">
      <v-card>
        <v-toolbar flat color="white" class="dialog-navigation">
          <v-toolbar-title>
            <i>AM NUSSBAUM</i>
          </v-toolbar-title>
          <v-spacer/>
          <v-toolbar-items>
            <div :class="['navigation-hamburger-menu', {'open':navigation.menu}]" @click="closeNavigationMenu()">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-main>
            <v-row>
              <v-col cols="11" offset="1" sm="12" class="pt-1 pb-1" v-if="isLandingPage()">
                <a class="main-link" @click="closeMenu('#location')">
                  Die Lage
                </a>
              </v-col>
              <v-col cols="11" offset="1" sm="12" class="pt-1 pb-1" v-if="isLandingPage()">
                <a class="main-link" @click="closeMenu('#building')">
                  Das Gebäude
                </a>
              </v-col>
              <v-col cols="11" offset="1" sm="12" class="pt-1 pb-1" v-if="isLandingPage()">
                <a class="main-link" @click="closeMenu('#apartments')">
                  Die Wohnungen
                </a>
              </v-col>
              <v-col cols="11" offset="1" sm="12" class="pt-1 pb-1" v-if="isLandingPage()">
                <a class="main-link" @click="closeMenu('#contact')">
                  Kontakt
                </a>
              </v-col>
            </v-row>
          </v-main>
        </v-container>
      </v-card>
    </v-dialog>

    <v-main>
      <router-view/>
    </v-main>
    <v-fab-transition>
      <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom right color="secondary" @click="toTop">
        <v-icon>{{icons.arrowUp}}</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
import { mdiChevronUp } from '@mdi/js'

export default {
  name: 'App',
  data: () => ({
    icons: {
      arrowUp: mdiChevronUp
    },
    navigation: {
      scrolled: false,
      menu: false,
      position: 0,
      scrollingDown: false,
    },
    fab: false,
  }),
  mounted() {
    window.addEventListener('scroll', () => {
      this.navigation.scrolled = window.scrollY > 50;
      this.navigation.scrollingDown = this.navigation.position < window.scrollY;
      this.navigation.position = window.scrollY;
    });
  },
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    },
    onScroll() {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || document.documentElement.offsetTop || 0;
      this.fab = top > 50;
    },
    isLandingPage() {
      return this.$route.name === 'Home';
    },
    closeMenu(selector) {
      if (selector !== undefined && selector !== null) {
        this.globalEvents.$emit('goToElement', selector);
      }
      this.closeNavigationMenu();
    },
    openNavigationMenu() {
      this.navigation.menu = true;
    },
    closeNavigationMenu() {
      this.navigation.menu = false;
    },
  }
};
</script>

<style lang="scss">
@import "assets/scss/variables.scss";
@import "assets/scss/layout.scss";

/* latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 200;
  src: url(./assets/fonts/NaPDcZTIAOhVxoMyOr9n_E7ffAzHGIVzY4SY.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 200;
  src: url(./assets/fonts/NaPDcZTIAOhVxoMyOr9n_E7ffAzHGItzYw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/NaPecZTIAOhVxoMyOr9n_E7fdM3mDbRS.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/NaPecZTIAOhVxoMyOr9n_E7fdMPmDQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url(./assets/fonts/NaPDcZTIAOhVxoMyOr9n_E7ffBzCGIVzY4SY.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url(./assets/fonts/NaPDcZTIAOhVxoMyOr9n_E7ffBzCGItzYw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.navigation-hamburger-menu {
  width: 38px;
  height: 25px;
  position: relative;
  margin: 17px auto 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: white;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  span:nth-child(1) {
    top: 0;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  &.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0;
    left: 0;
  }
  &.open span:nth-child(2) {
    width: 0;
    opacity: 0;
  }
  &.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 26px;
    left: 0;
  }
  &.white-navigation span {
    background: white;
  }
}
.v-app-bar {
  height: 64px;
  margin-top: 0;
  transform: translateY(0px);
  left: 0;
  right: 0;
  background-color: rgba(66, 66, 66, 0.7) !important;
  border-color: rgba(66, 66, 66, 0.7) !important;
  &.scrolled {
    background-color: rgba(66, 66, 66, 0.9) !important;
    border-color: rgba(66, 66, 66, 0.9) !important;
  }
  .v-toolbar__content {
    margin-left: 185px;
    margin-right: 185px;
    @include MQ(L) {
      margin-left: 135px;
      margin-right: 135px;
    }
    @include MQ(M) {
      margin-left: 60px;
      margin-right: 60px;
    }
    @include MQ(S) {
      margin-left: 25px;
      margin-right: 25px;
    }
    @include MQ(XS) {
      margin-left: 0;
      margin-right: 0;
    }
    .v-toolbar__title {
      color: white;
      font-weight: bold;
      padding-right: 10px;
    }
    .v-btn {
      color: white;
    }
  }
}
.dialog-navigation-content {
  .dialog-navigation {
    .v-toolbar__title {
      padding-right: 10px;
    }
    .v-toolbar__items {
      .navigation-hamburger-menu {
        span {
          background: black;
        }
      }
    }
  }
  a {
    color: black !important;
    font-weight: 300;
    text-shadow: 0 3px 6px #00000029;
    letter-spacing: 0;
    &.main-link {
      font-size: 30px;
      @include MQ(XS) {
        font-size: 28px;
      }
    }
    &.sub-link {
      font-size: 25px;
      line-height: 30px;
      @include MQ(XS) {
        font-size: 20px;
      }
    }
  }
}
.v-btn {
  text-transform: none !important;
}
.v-application {
  font-family: $default-font !important;
}
</style>
