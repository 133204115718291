import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/es5/locale/de';
import minifyTheme from 'minify-css-string';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      options: {
          minifyTheme
      }
    },
    icons: {
        iconfont: 'mdiSvg',
    },
    lang: {
        locales: { de },
        current: 'de',
    },
});
